export const navlink1 = {
  ar: "الرئيسية ",
  en: "Home",
  fr: "Page d'accueil",
};
export const navlink2 = {
  ar: "الجهات المنظمة ",
  en: "Organizers",
  fr: "Les organisateurs",
};
export const navlink3 = {
  ar: "المتحدثون ",
  en: "Speakers",
  fr: "Haut-parleurs",
};
export const navlink4 = {
  ar: "عن المؤتمر ",
  en: "About",
  fr: "À propos",
};
export const navlink5 = {
  ar: "رعاة المؤتمر ",
  en: "Sponsors",
  fr: "Les sponsors",
};
export const navlink6 = {
  ar: "اللجان المنظمة ",
  en: "Supervisory committees",
  fr: "Comités de surveillance",
};
export const navlink7 = {
  ar: "مواعيد مهمة",
  en: "Important Dates",
  fr: "Rendez-vous importants",
};

export const head1 = {
  ar: "المؤتمر العلمي الاول بكلية النفط والغاز بجامعة الزاوية تحت شعار",
  en: "The first scientific conference of the Faculty of Petroleum and Gas Engineering at University of Zawia",
  fr: "La première conférence internationale du Maghreb",
};

export const head2 = {
  ar: "'دور علوم الأرض والبيئة في تنمية الاقتصاد الليبي'",
  en: "Role of earth and environmental sciences in developing Libyan economy'",
  fr: "les évolutions du développement durable «Réalité et Espoir»  ",
};

export const head3 = {
  ar: "إجمالي الورقات المستلمة ",
  en: "Total papers received",
  fr: "Total des articles reçus",
};
export const head4 = {
  ar: "عدد الورقات المقبولة ",
  en: "Total researchs accepted",
  fr: "Nombre d'articles acceptés",
};

export const head41 = {
  ar: "عدد الورقات المقبولة ",
  en: "Total Researchs Rejected",
  fr: "Nombre d'articles acceptés",
};

export const head5 = {
  ar: "موعد انعقاد المؤتمر بعد ",
  en: "",
};

export const head6 = {
  ar: "تمهيد",
  en: "Introduction",
  fr: "Contextes",
};
export const Node = {
  ar: "ملاحظة :",
  en: "notice",
  fr: "remarquer",
};
// export const TNode = {
//   ar: `السادة الباحثون نظرا لمطالبة العديد من الباحثين بتمديد فترة  قبول الملخصات و  ذلك لتزامنها مع  عطلة عيد الفطر المبارك
//   فلقد تقرر تمديد فترة قبول الملخصات الي  يوم 11-06-2020.والاعلان على الملخصات المقبولة 2020.06.12    . وبداية قبول الورقات البحثية كاملة..2020.06.13. واخر موعد قبول الورقات البحثية كاملة 2020.07.06 والاعلان عن الورقات المقبولة 2020.07.10 .وانعقاد المؤتمر 2020.07.12. `,
//   en: "",
// };

export const about1 = {
  ar: `نظراً لأهمية الدور الذي تؤديه علوم الأرض والبيئة في دراسة الموارد الطبيعية والتي تساهم في تحريك عجلة الاقتصاد والتنمية والطاقات المتجددة، سيتم عقد المؤتمر الأول لكلية هندسة النفط والغاز بالزاوية؛ مما يعطى للجامعات فرصة لخدمة التنمية المستدامة ومقوماتها الاقتصادية، من خلال تقديم الدراسات والاستشارات العلمية والفنية للموارد الطبيعية، وتسليط الضوء على الخدمات اللازمة لرجال الأعمال والمؤسسات العامة والخاصة بأهمية الدور الاقتصادي والبيئي للخامات المستخرجة من الأرض وتأثيرها قبل وبعد إنتاجها على البيئة، حتى نساهم في إقامة مشاريع جديد أو التوسيع في تحسين القائم منها على أحسن الطرق وإسناد الدور الريادي للقطاع الخاص في تحريك عجلة اقتصادنا الوطني بكل ما ينطوي عليه من تشجيع الاستثمار، والتنافس،  وإتاحة فرص عمل جديد أمام شبابنا من خلال دراسة أهمية الموارد الطبيعية في البلاد.
   `,
  en: `  In view of the importance of the role played by earth and environmental sciences in studying natural resources, which contribute to moving the wheel of economics, development. The first conference for earth sciences organized by the Faculty of Petroleum Engineering will be held in Zawia city, Libya, This scientific event gives universities an opportunity to serve sustainable development and its economic constituents, by providing studies, scientific and technical consultations for natural resources.
  The conference will also shed light on the services needed for the public and private institutions on the importance of the economic and environmental role of the materials extracted from earth and their impact on the environment before and after their production. Consequently, it contributes to the creation of new projects and the improvement of existing projects, using the best appropriate technologies. Also assigning the leading role of the private sector in economic development of our country, by encouraging investment, competition, and providing new job opportunities for our young people by studying the importance of natural resources in our country. 
  `,
  fr: `Avec le développement auquel le monde est témoin dans la pensée économique, le concept de développement a connu un ensemble de transformations et d'évolutions, et il a pris au début des années 70 une dimension plus grande que la dimension économique et la dimension sociale, et des nouveaux concepts ont été ajoutés, dont le plus important est le développement durable, ce concept est considéré comme nouveau et innovant, et aucun consentement sur ce concept à l’échelle mondiale, étant donné que le développement durable peut être considéré comme un changement social orienté selon une politique idéologique spécifique, qui est un processus de planification à long terme, complet et intégré dans ses dimensions économique, sociale, culturelle, environnementale et technologique, et vise à améliorer continuellement le bien-être des individus. Cette politique de développement ne doit pas se limiter à un secteur spécifique sans l'autre, ni reposer sur un seul axe. De ce point de vue, nous tenterons, à travers cette conférence, de faire la lumière sur les évolutions les plus importantes du développement durable entre réalité et aspirations au niveau stratégique pour différents pays. Les Pays arabes en général et les Pays du Maghreb en particulier, compte tenu des changements que traverses ces régions, notamment politique, économique et social, nous essayons de discuter en fonction d'un ensemble de situations centrales à travers le rôle du secteur public, du secteur privé et des organisations locales et internationales dans la mise en œuvre des objectifs du développement durable et leurs implications pour la Vision 2030 des Nations Unies.`,
};

export const about2 = {
  ar: `للتعرف و لتحميل قالب النشر  :`,
  en: "Journal publishing rules and conditions",
  fr: `Règles et conditions de publication de revues`,
};
export const about3 = {
  ar: "اضغط هنا",
  en: "Click here",
  fr: `Cliquez ici`,
};
export const about4 = {
  ar: "قواعد وشروط النشر",
  en: "Journal publishing rules and conditions",
  fr: `Règles et conditions de publication de revues`,
};
export const about5 = {
  ar: `<li>أن يسهم البحث في تعميق المعرفة ضمن محاور المؤتمر</li>
    <li>
      أن يكون البحث مستوفيا لشروط البحث العلمي, بما في ذلك ملخص البحث
      وعرض المشكلة ومراجعة الأدبيات ذات العلاقة والمنهجية المتبعة و
      تحليل البيانات وعرض النتائج والتوصيات .
    </li>
    <li>تقبل البحوث باللغة العربية واللغة الانجليزية .</li>
    <li>إتباع القواعد اللغوية السليمة في كتابة البحث.</li>
    <li>
      ألا يكون البحث جزءاً من رسالة الماجستير أو الدكتوراه التي أعدها
      الباحث, وألا يكون قد سبق نشره , أو تم إرساله للنشر في مجلة اخرى
      وألا يكون قد تمت المشاركة به في مؤتمر أو ندوة أو ورشة عمل.
    </li>
    <li>
      أن يكون البحث مكتوبا على ورق حجم A4 بحيث لا يقل حجم البحث عن 15
      صفحة و لا يزيد على 25 صفحة.
    </li>
    <li>
      تخضع البحوث المقدمة إلى المجلة للفحص والتقييم العلمي حسب الأصول
      المتبعة ، وتتحدد صلاحيتها للنشر بناء على رأي المحكمين بها.
    </li>
    <li>
      
      يلتزم الباحث بإجراء تعديلات المحكمين بالمؤتمر على بحثه وفق
      التقارير المرسلة إليه، وموافاة المجلة بنسخة معدلة خلال مدة لا
      تزيد عن (15) يوماً .
    </li>
    <li>
      لا يجوز للباحث أن يطلب عدم نشر بحثه بعد إرساله للتحكيم إلا
      لأسباب تقتنع بها هيأة التحرير.
    </li>
    <li>
      
      يرفق بالبحث ملخص باللغتين العربية والانجليزية بما لا يزيد عن
      (200) كلمة، وتلي الملخص كلمات مفتاحية دالة لا تزيد عن (7 )
      للتعريف بنواحي البحث الرئيسة.
    </li>
    <li>
      يحق لهيأة التحرير - إذا رأت ضرورة لذلك - إجراء بعض التعديلات على
      البحث من دون المساس بمضمونه وبما يتوافق مع أسلوب المجلة في النشر
      .
    </li>
    <li>
      
      يكتب الباحث على ورقة منفصلة اسمه بالكامل ووظيفته الحالية ودرجته
      العلمية واسم المؤسسة التي يعمل بها وعنوانه مفصلاً وأرقام الهواتف
      والبريد الالكتروني، ويرفق مع البحث سيرة ذاتية مختصرة للتعريف
      بالباحث.
    </li>
    <li>
      يجب إثبات المصادر والمراجع في متن البحث بحيث يوضع اسم ولقب
      الباحث والسنة بين قوسين، وفي حالة الاقتباس المباشر يضاف رقم
      الصفحة.
    </li>
    <li>
      جميع المراجع المشار إليها في متن البحث يجب أن تدرج في قائمة
      المراجع في نهاية البحث قبل الملاحق – إن وجدت – وترتب ترتيباً
      هجائياً مبتدأه بالمراجع العربية أولاً ثم المراجع الأجنبية دون
      ترقيمها، وذلك وفقاً لما يلي
      <ul>
        <li>
          
          الكتب : اسم المؤلف أو المؤلفين ( السنة )، عنوان الكتاب ، اسم
          المحقق أو المترجم إن وجد ، (رقم الطبعة ؛ مكان النشر: اسم
          الناشر ) ، رقم الجزء . وفي حالة المراجع الأجنبية يكتب لقب
          المؤلف أو المؤلفين أولاً ثم بقية الاسم.
        </li>
        <li>
          
          البحث أو المقال المنشور في الدورية : اسم الكاتب أو الكتّاب (
          السنة) ، عنوان البحث أو المقال بين علامتي تنصيص ، اسم
          الدورية ، الجهة التي تصدر عنها الدورية، واسم البلد الصادرة
          عنها ، رقم المجلد ، رقم العدد . وفي حالة المراجع الأجنبية
          يكتب لقب الكاتب أو الكتّاب أولاً ثم بقية الاسم.
        </li>
        <li>
          الرسائل الجامعية : اسم مقدم الرسالة ( السنة) ،عنوان الرسالة
          أو الأطروحة، اسم الجامعة المانحة للرسالة.
        </li>
        <li>
          
          مصادر ومراجع الانترنت : اسم المؤلف أو المؤلفين (السنة)
          ،عنوان الكتاب أو المقال أو البحث ، اسم الموقع وعنوانه ،
          وتاريخ زيارة الموقع .
        </li>
      </ul>
    </li>
    <li>لا ينشر للباحث أكثر من بحث واحد في كل عدد.</li>
    <li>
      يعتبر البحث المنشور ملكاً للمجلة وبالتالي لا يجوز إعادة نشر أي
      بحث منشور أو تم قبوله للنشر بأي شكل من الأشكال أو بأي لغة في أي
      جهة أخرى، إلا بإذن رسمي من رئيس هيأة التحرير.
    </li>
    <li>
      تكون النواحي الفنية في أعداد البحث على النحو التالي :
      <ul>
        <li>
          
          تكون الكتابة للبحوث العربية بالخط (Simplified Arabic ) ،
          وللبحوث باللغة الانجليزية بالخط ( Times New Roman ) مع ترك
          مسافة مفردة بين الأسطر.
        </li>
        <li>
          أن يكون حجم الخط على النحو التالي:
          <ol>
            <li> 18 (داكن) للعناوين الرئيسة .</li>
            <li>16 (داكن) للعناوين الفرعية.</li>
            <li>
              14 للمتن باللغة العربية و 12 للمتن باللغة الانجليزية.
            </li>
            <li>
              12 مائل للملخص باللغة العربية و 10 للملخص باللغة
              الانجليزية.
            </li>
            <li>10 للشرح أو التوضيح أسفل الصفحة.</li>
          </ol>
        </li>
        <li>
          أن تكون الهوامش على النحو التالي :
          <ol>
            <li>أعلى وأسفل 2.5 سم .</li>
            <li>أيمن 3 سم .</li>
            <li>أيسر 2.5 سم.</li>
          </ol>
        </li>
      </ul>
    </li>
    <li>
      يحق للجنة العلمية رفض الورقة العلمية بدون ابداء اسباب الرفض.
    </li>
    <li>
      تسلم البحوث والدراسات وجميع المراسلات المتعلقة من خلال رابط التسجيل المخصص لاستقبال البحوث الموجود بالموقع الالكتروني .
    </li>`,
  en: `<p>Interventions and research papers that meet the requirements will be published in a special proceedings of the conference journal, according to the following conditions:</p>
  <ol>
  <li>That the research contributes to deepening knowledge within the conference axes.</li>
  <li>That the research meets the requirements of scientific research, including the summary of the research, presentation of the problem, review of the relevant literature and the methodology used, data analysis and presentation of findings and recommendations.</li>
  <li>Research is accepted in Arabic and English.</li>
  <li>Proper linguistic rules are followed in writing the research.</li>
  <li>The research should not be part of a Master's or Doctoral thesis, it should not have been previously published or sent for publication to another journal, and it has not been presented in another conference, seminar or workshop.</li>
  <li>The research paper is between 15 and 25 pages.</li>
  <li>The research should be written on A4 size paper.</li>
  <li>Research submitted to the journal is subject to scientific examination and evaluation according to the established rules, and its suitability for publication is determined by the Editorial Board.</li>
  <li>The researcher is obliged to accept the judgement of the editorial board on his/her research and, according to feedback provided, should present a modified copy within a period not exceeding 15 days.</li>
  <li>The researcher may not request that his/her research not be published after sending it for evaluation, except for reasons agreed by the editorial board.</li>
  <li>Attached to the research should be a summary in Arabic and English of no more than 200 words, followed by no more than 7 key words to define the main aspects of the research.</li>
  <li>The Editorial Board has the right - if it deems necessary - to make some adjustments to the research, without prejudice to its content, and in accordance with the journal's method of publication.</li>
  <li>The researcher should write on a separate paper his/her full name, current job, academic degree, the name of the institution he/she works for, a detailed address, phone number and e-mail, and attach to the research a brief CV.</li>
  <li>Research sources must be referenced in the body of the research, giving name of the researcher/s and the year in parentheses. In the case of a direct quotation, the page number should be added.</li>
  <li>All references referred to in the body of the research must be included in the list of references at the end of the research, before the annexes - if any - and arranged in alphabetical order, starting with the Arabic references first and then the foreign references, according to the following:<ol>
  <li>Books:The name of the author or authors (year), the title of the book, the name of the  translator, edition number, place of publication: name of the publisher. In the case of foreign references, the surname of the author or authors is written first and then their initials.
  
  </li>
  <li>Journal publication:The name of the author or writers (year), the title of the research or article in quotation marks, the name of the journal, the volume number, issue number and page numbers.</li>
  <li>Theses:The name of the author of the thesis (year), the title of the thesis or thesis, the name of the university providing the thesis.</li>
  <li>Internet resources and references:The name of the author or authors (year), the title of the book, article or research, the URL of the site, and the date the site was accessed.</li>
   </ol>
   </li>
  <li>No more than one research paper per researcher will be published in the proceedings.</li>
  <li>The published research is considered the copyright property of the journal and therefore it is not permissible to republish in any way or in any language in any other publication, except with the official permission of the Editor-in-Chief.</li>
  <li>The technical aspects for papers are as follows:
  <ol>
  <li> Times New Roman and Simplified Arabic- Single spaced.- Font size:18 bold for headlines-16 bold for subtitles.</li>
  <li>14 for the body in Arabic and 12 for the body in English.</li>
  <li>12 italic for abstract in Arabic and 10 for abstract in English.</li>
  <li>10 for explanation or clarification at the bottom of the page.</li>
  <li>The margins should be as follows:2.5 cm up and down, 3 cm right, 2.5 cm left.</li>
  </ol>
  </li>
  <li>All papers and correspondence to be submitted only through the designated address given in the conference announcements.</li>
  </ol>
  <p>Journal of the Faculty of Economics – University of Zawia, Zawiya-Libya</p>
  `
};

export const about6 = {
  ar: ` مجلة كلية الاقتصاد – جامعة الزاوية ، الزاوية ليبيا .`,
  en: `Journal of the Faculty of Economics – University of Zawia, Zawiya, Libya`,
};
export const Gools1 = {
  ar: `أهداف المؤتمر`,
  en: `The objectives of the conference`,
  fr: `Les objectifs de la conférence`,
};
export const Gools2 = {
  ar: `<li> تقديم المشورة لمتخذي القرار باستغلال الموارد الطبيعية المتواجدة بالأراضي الليبية والتي أثبتت الدراسات الجيولوجية والبيئية أهميتها وجدواها الاقتصادية.
  </li>
  <li>توجيه المؤسسات الخاصة والقطاع العام لاستغلال الموارد الطبيعية في مجالات التصنيع المختلفة.
  </li>
  <li> إيجاد فرص عمل لخريجي المعاهد والكليات الفنية والهندسية من خلال استثمار الموارد الطبيعية المتوفرة بالبلاد.
  </li>
  <li>توجيه متخذي القرار إلى حسن استغلال الموارد الطبيعية المشتركة مع دول الجوار وبالذات في مجال المياه والتعدين.
  </li>
  <li>وضع ومراجعة قوانين تُنظم استغلال الموارد الطبيعية والبيئية.
  </li>
 `,
  en: `<li>Finding job opportunities for graduates of technical and engineering institutes and colleges by investing the available natural resources in the country.
  </li>
  <li>Providing an advice to the decision makers to exploit the natural resources found in Libyan, where geological and environmental studies have proven their importance and economic feasibility.</li>
 <li>Directing the decision makers to the proper exploitation of the natural resources shared with the neighboring countries, particularly in the field of water and mining.</li>
 <li> Directing private institutions and public sector to exploit natural resources in various manufacturing fields.</li>
 <li>Developing and reviewing laws that regulate the exploitation of natural and environmental resources.</li> `,
  fr: `<li>Clarifier les différents défis et obstacles auxquels sont confrontés les Pays arabes en général et Maghrébins en particulier, afin d'activer les dimensions du développement durable.</li>
 <li>Elaborer une feuille de route contenant des mesures nécessaires pour parvenir à un développement durable et global à tous les niveaux et domaines.</li>
 <li>Réaliser un équilibre structurel entre les différents tant secteurs public que privé, actifs dans le domaine du développement durable</li>
 <li>Utiliser davantage les partenariats entre les différentes parties et organisations régionales et internationales concernées par les questions du développement durable.</li>
 <li>Établir des politiques et des programmes qui permettent l'avancement de la région dans divers domaines afin de faire face aux crises d’urgence.</li>
 <li>Établir un plan d'action permettant de réaliser un développement durable dans la région après la pandémie de Corona.</li>`,
};

export const m1 = {
  ar: "محاور المؤتمر",
  en: "Topics of the Conference",
  fr: "Axes de la conférence",
};
export const mm1={
ar:"المستهدفون بالمشاركة",
en:"The Target Participants"
};
export const mm2={
  ar:"شروط المشاركة",
  en:"Conditions & Terms"
};
export const m2 = {
  ar:
    "المحور الأول | الخامات المعدنية في ليبيا",
  en:
    "The first topic | Mineral ores in Libya",
  fr: `Le premier axe | Développement durable au Maghreb (réalité, défis)`,
};
export const m3 = {
  ar: `<ol>
<li>التقنيات المستخدمة في عمليات الاستكشاف عن الخامات المعدنية.</li>
<li>التقييم الاقتصادي للخامات المعدنية.</li>
<li>تأثير استخراج الموارد الطبيعية على البيئة</li>
  </ol>`,
  en: `<ol>
  <li>Techniques used in the exploration processes for mineral ores.</li>
  <li>Economic evaluation of mineral ores.</li>
  <li>The impact of natural resource extraction on the environment</li>

</ol>`,
  fr: `<ol>
<li>Concepts et visions du développement durable.</li>
<li>Rapports (locaux, régionaux, internationaux) sur le suivi et la mise en œuvre des objectifs de développement durable.</li>
<li>Intégration des trois dimensions du développement durable (économique, sociale et environnementale).</li>
</ol>`,
};
export const m4 = {
  ar: "المحور الثاني |النفط والبيئة ",
  en: "The second topic | Oil and the environment",
  fr: `Le deuxième axe | Développement durable et crises `,
};
export const m5 = {
  ar: `<ol>
  <li>استكشاف وتطوير انتاج الحقول النفطية.</li>
  <li>تلوث البيئة بالمخلفات النفطية وطرق مكافحته.</li>
  <li>النفط الصخري والعقبات الفنية لاستخراجه في ليبيا.</li>
    </ol>`,
  en: `	<ol>
  <li>Exploring and developing oil fields production.</li>
<li>Pollution of the environment by oil waste and the methods to combat it.</li>
<li>Shale oil and the technical challenges for its extraction in Libya.</li>
  </ol>`,
  fr: `<ol>
<li>La réalité du développement durable dans les zones de conflits (opportunités et menaces). </li>
<li> Le rôle des compétences et des aptitudes humaines dans la réalisation du développement durable à la lumière des crises.</li>
<li> Défis et difficultés pour parvenir à un développement durable dans les pays du Maghreb (Cas de la pandémie Corona).</li>
</ol>`,
};
export const m6 = {
  ar: `المحور الثالث |المياه والبيئة`,
  en: "The third topic |Water and the Environment",
  fr: `Le troisième axe | Développement économique durable`,
};
export const m7 = {
  ar: `<ol>
<li>المياه المالحة الجوفية واستخداماتها المختلفة.</li>
  <li>الحلول المبتكرة لشح المياه، وإدارة الموارد المائية.</li>
  <li>المياه والصحة (العلاج الطبيعي). </li>
  <li>تلوث المياه الجوفية.</li>
  
  </ol>`,
  en: `<ol>
  <li>The Groundwater water salted and its various uses.</li>
  <li>Innovative solutions to water scarcity and water resource management.</li>
  <li>Water and health (physical therapy).</li>
  <li>Groundwater pollution.</li>

  </ol>`,
  fr: `<ol>
  <li>Politiques de marketing (économique, touristique, E-Marketing) pour parvenir à une économie maghrébine durable. </li>
  <li>De nouveaux et meilleurs mécanismes du travail et de l'emploi pour parvenir à une économie durable. </li>
  <li>Construire des partenariats pour améliorer les économies arabes – Cas de l'investissement étranger. </li>
</ol>`,
};

export const m8 = {
  ar: `المحور الرابع |  السياحة الجيولوجية `,
  en: `The fourth topic | Geological tourism`,
  fr: `Quatrième axe | Développement environnemental durable`,
};
export const m9 = {
  ar: ` <ol>
  <li>الإمكانيات الجيولوجية للمناطق الجبلية لإنشاء مشاريع سياحية.</li>
  <li>مساهمة المظاهر الجيولوجية والبحيرات الطبيعية لدعم السياحة في ليبيا. </li>
  </ol>`,
  en: `<ol>
<li>Geological potential of mountainous regions to establish tourism projects.</li>
<li>Contribution of geological features and natural lakes to support tourism in Libya.</li>
</ol>`,
  fr: `<ol>
<li>Politiques agricoles durables (biologiques, naturelles et domestiques). </li>
<li>Protection de l'environnement pour réduire la pollution, les restes des guerres et des conflits (sol, eau, nourriture et faune ...). </li>
<li>Mécanismes et modèles de recyclage aux niveaux local, régional et mondial.</li>
</ol>`,
};
export const m10 = {
  ar: `المحور الخامس |  التقنيات الجيوفيزيائية `,
  en: `The fifth topic | Geophysical techniques`,
  fr: `Le cinquième axe Gouvernance et bonne gouvernance pour le développement durable`,
};
export const m11 = {
  ar: ` <ol>
  <li>تطبيق التقنيات الجيوفيزيائية في مجالات البنية التحتية والبيئة.</li>
  <li>آثار ظاهرة الهبوط على البُنية التحتية والبيئة وطرق تجنبها.</li>
  
</ol>`,
  en: `<ol>
<li>Application of geophysical techniques in fields of infrastructure and environment.</li>
<li>Impact of Land subsidence on infrastructure and environment and ways to avoid it.</li>
<li>Remote sensing techniques and spatial information systems.</li>
</ol>`,
  fr: `<ol>
<li>Bonne gouvernance efficace (gestion de l'argent et rationalisation des dépenses publiques, financement des administrations locales et moyens pour le développement et numérisation du secteur administratif).</li>
<li>Le rôle des politiques fondées sur des preuves, des données et des études dans la réalisation du développement durable.</li>
<li>Villes intelligentes et moyens de parvenir au développement durable.</li>
</ol>`,
};
export const m12 = {
  ar: `المهندسون والجيولوجيون والجيوفيزيائيون والبحاثة والأكاديميون `,
  en: `Engineers, geologists, geophysicists, researchers, and academics`,
  fr: `Sixième axe | Développement social durable `,
};
export const m13 = {
  ar: ` <ol>
  <li>أثر التكامل الاجتماعي   في دعم التنمية المستدامة .</li>
  <li>دور التشريعات العامة المحلية/الوطنية/الدولية في الحد من (الفقر , العمالة الهشة, البطالة , اللاجئين والمهاجرين) .</li>
 <li>تضمين قضايا وحقوق  (المرأة  ,الشباب , وكل المكونات الاجتماعية  ) في برامج التنمية المستدامة.</li>
 
  </ol>`,
  en: `<ol>
  <li>The impact of social integration in support of sustainable development.</li>
  <li>The role of general local / national / international legislation in reducing poverty, vulnerable employment, unemployment, refugees and immigrants.</li>
  <li>Including issues and rights (women, youth, and all social components) in sustainable development programs.</li>
  </ol>`,
  fr: `<ol>
  <li>L'impact de l'intégration sociale au service du développement durable.</li>
  <li>Le rôle de la législation générale locale / nationale / internationale dans la réduction (pauvreté, emploi vulnérable et précaire, chômage, réfugiés et immigrants).</li>
  <li>Inclure les questions et les droits (femmes, jeunes et toutes les composantes sociales) dans les programmes de développement durable.</li>
  </ol>`,
};

export const m14 = {
  ar: ` رجال الاعمال والمستثمرون وغرف الصناعة والزراعة `,
  en: `Businessmen, investors, and chambers of industry and agriculture`,
  fr: `Le septième axe | Investir dans les ressources humaines et son rôle dans le soutien du développement durable`,
};
export const m15 = {
  ar: ` <ol>
  <li>التعليم والتدريب الاستراتيجي ( التحول الرقمي, جودة التعليم  , تطوير البنية التحتية ) .</li>
  <li>جودة الخدمات الصحية ودورها في دعم التنمية المستدامة .</li>
  <li>دعم المشروعات الصغيرة والمتوسطة والابتكارات  لذوي القدرات المهنية .</li>
  
</ol>`,
  en: `<ol>
<li>Strategic education and training (digital transformation, quality education, infrastructure development).</li>
<li>The quality of health services and their role in supporting sustainable development.</li>
<li>Supporting small and medium enterprises and innovations for people with professional capabilities.</li>
</ol>`,
  fr: `<ol>
<li>Enseignement et formation (entrainement) stratégiques (transformation numérique, enseignement de qualité, développement des infrastructures). </li>
<li>La qualité des services de santé et leur rôle dans le soutien du développement durable. </li>
<li>Le soutien et l’encouragement des petites et moyennes entreprises et la promotion des innovations pour ceux qui ont des capacités professionnelles.</li>
</ol>`,
};
export const m16 = {
  ar: `الكليات الفنية، والمعاهد التخصصية، والمراكز البحثية، والمؤسسات والشركات ذات العلاقة. `,
  en: `Technical colleges, specialized institutes, research centers, related institutions and companies.`,
  fr: `Le huitième axe | La sécurité intellectuelle et son rôle dans la réalisation du développement durable`,
};
export const m17 = {
  ar: ` <ol>
  <li>الآليات التطبيقية لحماية المورد البشري من مختلف أشكال العنف والتطرف .</li>
  <li>التنمية في بلدان المغرب العربي بين الارضية المشتركة والاختلافات المحتملة (البنية العربية المغاربية ) .</li>
  <li>دور السلام الايجابي، الاستقرار، وفض النزاعات  في تحقيق التنمية المستدامة .</li>
  
  </ol>`,
  en: `<ol>
  <li>The applied mechanisms to protect human resource from various forms of violence and extremism.</li>
  <li>Development in the countries of the Maghreb between common ground and potential differences (the Arab Maghreb structure).</li>
  <li>The role of positive peace, stability, and conflict resolution in achieving sustainable development.</li>
  </ol>`,
  fr: `<ol>
  <li>Les mécanismes appliqués pour protéger les ressources humaines contre diverses formes de violence et d'extrémisme. </li>
  <li>Développement dans les pays du Maghreb entre terrain d'entente et différences potentielles (structure des pays du Maghreb). </li>
  <li>Le rôle de la paix positive, de la stabilité et de la résolution des conflits dans la réalisation du développement durable. </li>
  </ol>`,
};
export const m18 = {
  ar: `المسئولون وصناع القرار`,
  en: `Officials and decision-makers`,
  fr: `Neuvième axe | Ressources naturelles et énergies renouvelables comme stratégie pour soutenir le développement durable`,
};
export const m19 = {
  ar: ` <ol>
  <li>حوكمة قطاع النفط لتحقيق أهداف التنمية المستدامة.</li>
  <li>الاستثمار في الموارد الطبيعية والطاقات المتجددة وسبل التنويع والاستخدام.</li>
  <li>نماذج وانماط دولية رائدة في استخدام تقنيات الطاقات المتجددة الخضراء.</li>
  
  </ol>`,
  en: `<ol>
  <li>Governing the oil sector to achieve sustainable development goals.</li>
  <li>Investing in natural resources, renewable energies, and ways of diversification and use.</li>
  <li>Leading international models and patterns in the use of green renewable energy technologies.</li>
  </ol>`,
  fr: `<ol>
  <li>Gouverner le secteur pétrolier pour atteindre les objectifs de développement durable. </li>
  <li>Investissement dans les ressources naturelles, les énergies renouvelables et les modes de diversification et d'utilisation.</li>
  <li>Principaux modèles internationaux d'utilisation des technologies d'énergie renouvelable verte.</li>
  </ol>`,
};
export const m20 = {
  ar: `الجمعيات العلمية وجمعيات المجتمع المدني، وغيرهم من المهتمين بالبيئة.`,
  en: ` Scientific societies, civil society organizations, and others interested in the environment.`,
  fr: `Axe X | Expériences internationales réussies pour atteindre les objectifs de développement durable `,
};
export const m21 = {
  ar: ` <ol>
  <li>مقاربات حول السياسيات والاستراتيجيات للدول الصاعدة والمتقدمة في التنمية المستدامة. </li>
  <li>دور المنظمات والهيئات العربية والاقليمية والدولية في تفعيل التنمية المستدامة. </li>
  <li>امكانيات تطبيق اهداف التنمية المستدامة في العالم العربي والمغاربي.</li>
  
  </ol>`,
  en: `<ol>
  <li>Approaches about policies and strategies for emerging and developed countries in sustainable development.</li>
  <li>The role of Arab, regional and international organisations and bodies in activating sustainable development.</li>
  <li>The possibilities of implementing the goals of sustainable development in the Arab and Maghreb world.</li>
  </ol>`,
  fr: `<ol>
  <li>Approches des politiques et stratégies des pays émergents et développés en matière de développement durable.</li>
  <li>Le rôle des organisations et des structures arabes, régionales et internationales dans la mise en œuvre du développement durable.</li>
  <li>Les possibilités de mise en œuvre des objectifs de développement durable dans le monde arabe et maghrébin.</li>
  </ol>`,
  
};
export const mm3={
ar:'الوكالات السياحية والترفيهية',
en:'Tourist and entertainment agencies'
};
export const mm4={
  ar:' طلبة أقسام الجيولوجيا والهندسة بأنواعها.',
  en:'Students of all departments of geology and engineering.'
  };
export const mm5={
ar:'أن يلتزم المشارك بأوراق بحثية بالشروط واللوائح المتعارف عليها في مجال البحث العلمي. ',
en:'The participant must adhere to terms and regulations in the field of scientific research.',
};
export const mm6={
  ar:'أن يكون البحث أو الورقة في صلب المحور المختار ويتماشى مع أهداف المؤتمر.',
  en:'The research or paper submitted must be related to the conference title and its sessions',
  };
export const mm7={
    ar:'ألا يكون البحث أو الورقة قد نشرت أو عرضت من قبل.',
    en:'The research or paper submitted has not been published or presented before elsewhere.',
    };
export const mm8={
      ar:'أن يلتزم بالشروط المرفقة لتقديم الورقة والبحث.',
      en:'The participant must abide by the attached conditions for presenting a paper or a research.',
      };
      export const mm9={
        ar:'أن يقدم البحث في موعده المحدد من قبل اللجنة العلمية للمؤتمر.',
        en:'The research or the paper must be submitted on the specified date set by the conference\'s scientific committee.',
        };
        export const mm10={
          ar:'البحوث المقبولة والغير مقبولة تعتبر ملك لإدارة الجامعة.',
          en:'The acceptable and unaccepted papers are considered to be Zawia University’s ownership.',
          };
          export const mm11={
            ar:'يمكن الاشتراك بمعلقات حائطية وبنفس الشروط السابقة.',
            en:'It is possible to subscribe by board posters on the same previous conditions.',
            };
            export const mm12={
              ar:'المصانع والمستثمرون يمكنهم المشاركة في المعرض بمنتجاتهم أو دراساتهم الاستثمارية.',
              en:'',
              };
export const s = {
  ar: `رعاة المؤتمر ...`,
  en: `Sponsors`,
  fr: `Les sponsors`,
};

export const comission = {
  ar: `لجان الإشراف على المؤتمر ...`,
  en: `Supervisory committees`,
  fr: `Comités de surveillance`,
};

export const c1 = {
  ar: `المنسقون...`,
  en: `Coordinators`,
  fr: `coordinateurs`,
};

export const c2 = {
  ar: `
  
  <div className="col m6">
      <ol>
      <li>أ. عماد الاشخم - ليبيا  <i
      class="material-icons teal-text"
      style={{ marginLeft: "15px" }}
    >
    phone
    </i> 00218925199849 </li>

      </ol>
      <h6>لمزيدا من المعلومات الرجاء مراسلتنا على البريد الالكتروني التالي: sc@zu.edu.ly</h6>
    </div>
`,
  en: `<div className="col m6">
   <ol>
    <li>Emad Alashkham - Libya<i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
  phone
  </i> 00218925199849 </li>
 
</ol>
<h6>If you have any questions, Please email us at:  sc@zu.edu.ly</h6>
</div>`,

  fr: `<div className="col m6">
<ol>
 <li>Hassan Alshibany - Libya<i
 class="material-icons teal-text"
 style={{ marginLeft: "15px" }}
>
phone
</i> 00218913672429 </li>
<li>
Morsali Houri - Algeria<i
 class="material-icons teal-text"
 style={{ marginLeft: "15px" }}
>
phone
</i>
00213798481647
</li>
<li>Munther Al-Kabeer - Malaysia  <i
class="material-icons teal-text"
style={{ marginLeft: "15px" }}
>
phone
</i> 00601137011603</li>
</ol>
<h5>Si vous avez des questions, veuillez nous envoyer un courriel à FIMSD2021@zu.edu.ly</h5>
</div>`,
};

export const D1 = {
  ar: `مواعيد مهمة`,
  en: `Important Dates`,
  fr: `Rendez-vous importants`,
};
export const D2 = {
  ar: `<ol> 

<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  اخر موعد لاستلام الورقات العلمية: 15-2-2021 
   </li>

<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  موعد انعقاد المؤتمر: 15 / 16 -3-2021
   </li>

<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
   الرحلة الحقلية على هامش المؤتمر: 17 / 18 -3-2021
</li>
  <li>
  <span class=" red-text"> ملاحظات:</span>   </br>
   سوف تكون هناك رحلة حقلية مجانية للمشاركين بالمؤتمر الى مدينتي غريان وجادو 
</li>
</ol>
`,

  en: `<ol> 

<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  Deadline for receiving papers: 15-Feb-2021
   </li>

<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  Conference Date due: 15-Mar-2021 to 16-Mar-2021
</li>

<li>
  <i
    class="material-icons teal-text"
    style={{ marginLeft: "15px" }}
  >
    check
  </i>
  Field trip: 17 / 18 -Mar-2021
  </li>
</ol>
<h5>Notes:</h5>   
<ol>
<li>  
<i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
  check
</i>
There will be a free field trip for the conference participants to the cities of Gharyan and Jadoo.
</li>

</ol>
`,
  fr: `<ol> <li>
<i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
  check
</i>
Conference announcement: 15-September-2020
</li>
<li>
<i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
  check
</i>
Paper Submission due: 01-Octobre-2020 to 15-December-2020 
 </li>

<li>
<i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
  check
</i>
Conference Date due: 05-février-2021 to 09-février-2021
</li>
<li>
<i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
  check
</i>
Training day: 08-février-2021
</li>
<li>
<i
  class="material-icons teal-text"
  style={{ marginLeft: "15px" }}
>
  check
</i>
Tourist trip: 09-février-2021
</li>
</ol>
<h5>Remarques:</h5>   
<ol>
<li>  
<i
class="material-icons teal-text"
style={{ marginLeft: "15px" }}
>
check
</i>
Si le participant n'est pas en mesure de se rendre sur le lieu de la conférence, il peut consulter le papier par voie électronique via l'espace électronique.
</li>
<li>  
<i
class="material-icons teal-text"
style={{ marginLeft: "15px" }}
>
check
</i>
La participation et la publication à la conférence sont gratuites.
</li><li>  
<i
class="material-icons teal-text"
style={{ marginLeft: "15px" }}
>
check
</i>
Hébergement et hébergement gratuits sur le lieu de la conférence à Tunis.</li>
<li>  
<i
class="material-icons teal-text"
style={{ marginLeft: "15px" }}
>
check
</i>
La conférence n'est pas responsable des frais de transport et de déplacement.
</li>
</ol>
`,
};

export const footer = {
  ar: ` الموقع الالكتروني - جامعة الزاوية`,
  en: `Website Department-Unversity of Zawia`,
  fr: `Website Department-Unversity of Zawia`,
};

export const Close = {
  ar: `اغلاق`,
  en: `Close`,
  fr: `Fermer`,
};

export const Bosses = [
  {
    image: "./img/team/emad.jpg",
    name: "أ.عماد الاشخم",
    type: "preparatory",
    capacity: "رئيس اللجنة التحضيرية",
    committe: "التحضيرية",
  },
  
  {
    image: "./img/team/yousf.jpg",
    name: "د.يوسف المنصوري",
    type: "scientific",
    capacity: "رئيس اللجنة العلمية",
    committe: "العلمية",
  },
  {
    image: "./img/team/khdrawy.jpg",
    type: "none",
    name: "أ.عبد العظيم محمد الخدراوي",
    capacity: "نائب رئيس المؤتمر",
    // capacity1: "عميد الكلية ",
  },
  {
    image: "./img/team/omar.jpg",
    type: "none",
    name: "د.عمر سلطان",
    capacity: "رئيس المؤتمر",
    // capacity1: "عميد الكلية ",
  },
 
  {
    image: "./img/team/meftah.jpg",
    type: "none",
    name: "د.مفتاح المرابط ",
    capacity: "الرئيس الشرفي للمؤتمر",
  },
];

 export const CommiteeMembers = [

{
  image: "",
  sex: "ذكر",
  name: "د.الهادي شيوه",
  type: "preparatory",
  capacity: "نائب رئيس اللجنة التحضيرية",
},
{
  image: "",
  sex: "ذكر",
  name: "	أ.عبدالحميد المرابط ",
  type: "preparatory",
  capacity: "عضو اللجنة التحضيرية",
},
{
  image: "",
  sex: "انثى",
  name: "د.نادية السباني ",
  type: "preparatory",
  capacity: "عضو اللجنة التحضيرية",
},
{
  image: "",
  sex: "ذكر",
  name: "أ.احمد العربي ",
  type: "preparatory",
  capacity: "عضو اللجنة التحضيرية",
},
{
  image: "",
  sex: "انثى",
  name: "أ.لطفية حدود",
  type: "preparatory",
  capacity: "عضو اللجنة التحضيرية",
},
{
  image: "",
  sex: "ذكر",
  name: "أ.على شلادى",
  type: "preparatory",
  capacity: "عضو اللجنة التحضيرية",
},
{
  image: "",
  sex: "ذكر",
  name: "أ.عبدالسلام عبدو",
  type: "preparatory",
  capacity: "عضو اللجنة التحضيرية",
},
{
  image: "",
  sex: "ذكر",
  name: "أ.علي قنوص ",
  type: "preparatory",
  capacity: "عضو اللجنة التحضيرية",
},
{
  image: "",
  sex: "ذكر",
  name: "د.أسامة بشينة",
  type: "preparatory",
  capacity: "عضو اللجنة التحضيرية",
},
{
  image: "",
  sex: "ذكر",
  name: "أ.كريم شعيب",
  type: "preparatory",
  capacity: "عضو اللجنة التحضيرية",
},
{
  image: "",
  sex: "ذكر",
  name: "أ.عبدالباري المريمي ",
  type: "preparatory",
  capacity: "عضو اللجنة التحضيرية(منسق المعرض)",
},
{
    image: "",
    sex: "ذكر",
    name: "أ.عبدالفتاح المزوغي ",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية(منسق المعرض)",
  },

{
  image: "",
  sex: "انثى",
  type: "preparatory",
  name: "أ.عفاف شيوب",
  capacity: "عضو اللجنة التحضيرية (الاستقبال)",
},
{
  image: "",
  sex: "ذكر",
  type: "preparatory",
  name: "أ.رضاء فحيل البوم",
  capacity: "عضو اللجنة التحضيرية (اللجنة الإعلامية)",
},
{
  image: "",
  sex: "ذكر",
  type: "preparatory",
  name: "أ.محمد عثمان",
  capacity: "عضو اللجنة التحضيرية (اللجنة الإعلامية)",
},
{
  image: "",
  sex: "ذكر",
  type: "preparatory",
  name: "أ.ابوبكر المجقجق",
  capacity: "عضو اللجنة التحضيرية (لجنة الخدمات)",
},
{
  image: "",
  sex: "ذكر",
  type: "preparatory",
  name: "أ.علون سلمان",
  capacity: "عضو اللجنة التحضيرية (لجنة الخدمات)",
},

///////////////////////////////////////////////*اللجنة العلمية*//////////////////////////////////////////////////////

{
  image: "",
  sex: "ذكر",
  name: "د.لطفى علي مادي",
  type: "scientific",
  capacity: "نائب رئيس اللجنة العلمية",
},
{
  image: "",
  sex: "ذكر",
  name: "أ.على جلول ",
  type: "scientific",
  capacity: "عضو اللجنة العلمية",
},
{
  image: "",
  sex: "انثى",
  name: "د. نورية الهادي أبوشعالة ",
  type: "scientific",
  capacity: "عضو اللجنة العلمية",
},
{
  image: "",
  sex: "انثى",
  name: "د.نادية حسين السباني ",
  type: "scientific",
  capacity: "عضو اللجنة العلمية",
},
{
  image: "",
  sex: "ذكر",
  name: "د.علي احمد مخروف",
  type: "scientific",
  capacity: "عضو اللجنة العلمية",
},
{
  image: "",
  sex: "ذكر",
  name: "د.نوري محمد فلو",
  type: "scientific",
  capacity: "عضو اللجنة العلمية",
},
{
  image: "",
  sex: "ذكر",
  name: "د. علي ابوسلوعة",
  type: "scientific",
  capacity: "عضو اللجنة العلمية",
},
{
  image: "",
  sex: "ذكر",
  name: "د.محمد الغضبان",
  type: "scientific",
  capacity: "عضو اللجنة العلمية",
},
{
  image: "",
  sex: "ذكر",
  name: "أ.محمد قباصة",
  type: "scientific",
  capacity: "عضو اللجنة العلمية",
},
{
  image: "",
  sex: "ذكر",
  name: "أ.محمود الصادق بن عيسى",
  type: "scientific",
  capacity: "عضو اللجنة العلمية",
},
{
  image: "",
  sex: "ذكر",
  name: "أ. خالد حسن كوكو",
  type: "scientific",
  capacity: "عضو اللجنة العلمية",
},
{
  image: "",
  sex: "ذكر",
  name: "أ. هشام عبد الله عواج",
  type: "scientific",
  capacity: "عضو اللجنة العلمية",
},
{
  image: "",
  sex: "ذكر",
  name: "د.أبو القاسم عبد الفتاح الأخضر",
  type: "scientific",
  capacity: "عضو اللجنة العلمية",
},
{
  image: "",
  sex: "ذكر",
  name: "د.عادل علي كشلاف",
  type: "scientific",
  capacity: "عضو اللجنة العلمية",
},
{
  image: "",
  sex: "ذكر",
  name: "د.إبراهيم يوسف مرحيل",
  type: "scientific",
  capacity: "عضو اللجنة العلمية",
},
{
  image: "",
  sex: "ذكر",
  name: "د.خالد محمد عون",
  type: "scientific",
  capacity: "عضو اللجنة العلمية",
},
{
  image: "",
  sex: "ذكر",
  name: "د.أسامة احمد اهلال",
  type: "scientific",
  capacity: "عضو اللجنة العلمية",
},
{
  image: "",
  sex: "ذكر",
  name: "د.عبدالرزاق محمد ابوشاح",
  type: "scientific",
  capacity: "عضو اللجنة العلمية",
},
{
  image: "",
  sex: "ذكر",
  name: "أ. خالد احمد الفرد",
  type: "scientific",
  capacity: "عضو اللجنة العلمية",
},
 ];
export const OtherComitte = [
  
  ///////////////////////////////////////////////*اللجنة التحضيرية*//////////////////////////////////////////////////////

  {
    image: "",
    sex: "ذكر",
    name: "د.الهادي شيوه",
    type: "preparatory",
    capacity: "نائب رئيس اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "	أ.عبدالحميد المرابط ",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "ذكر",
    type: "preparatory",
    name: "د.يوسف الوافي",
    capacity: "عضو اللجنة التحضيرية",
  },
    {
    image: "",
    sex: "انثى",
    name: "د.نادية السباني ",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "أ.احمد العربي ",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "انثى",
    name: "أ.لطفية حدود",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "أ.على شلادى",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "أ.عبدالسلام عبدو",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "أ.علي قنوص ",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.أسامة بشينة",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "أ.كريم شعيب",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "أ.عبدالباري المريمي ",
    type: "preparatory",
    capacity: "عضو اللجنة التحضيرية(منسق المعرض)",
  },
{
      image: "",
      sex: "ذكر",
      name: "أ.عبدالفتاح المزوغي ",
      type: "preparatory",
      capacity: "عضو اللجنة التحضيرية(منسق المعرض)",
    },
  
  {
    image: "",
    sex: "انثى",
    type: "preparatory",
    name: "أ.عفاف شيوب",
    capacity: "عضو اللجنة التحضيرية (الاستقبال)",
  },
  {
    image: "",
    sex: "ذكر",
    type: "preparatory",
    name: "أ.رضاء فحيل البوم",
    capacity: "عضو اللجنة التحضيرية (اللجنة الإعلامية)",
  },
  {
    image: "",
    sex: "ذكر",
    type: "preparatory",
    name: "أ.محمد عثمان",
    capacity: "عضو اللجنة التحضيرية (اللجنة الإعلامية)",
  },
  {
    image: "",
    sex: "ذكر",
    type: "preparatory",
    name: "أ.ابوبكر المجقجق",
    capacity: "عضو اللجنة التحضيرية (لجنة الخدمات)",
  },
  {
    image: "",
    sex: "ذكر",
    type: "preparatory",
    name: "أ.علون سلمان",
    capacity: "عضو اللجنة التحضيرية (لجنة الخدمات)",
  },

  ///////////////////////////////////////////////*اللجنة العلمية*//////////////////////////////////////////////////////

  {
    image: "",
    sex: "ذكر",
    name: "أ.لطفى علي مادي",
    type: "scientific",
    capacity: "نائب رئيس اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "أ.على جلول ",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د. علي ابوسلوعة",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.محمد الغضبان",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "أ.محمد قباصة",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "انثى",
    name: "د. نورية الهادي أبوشعالة ",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "انثى",
    name: "د.نادية حسين السباني ",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.علي احمد مخروف",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.نوري محمد فلو",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "أ.محمود الصادق بن عيسى",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "أ. خالد حسن كوكو",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "أ. هشام عبد الله عواج",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.أبو القاسم عبد الفتاح الأخضر",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.عادل علي كشلاف",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د. إبراهيم يوسف مرحيل",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.خالد محمد عون",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.أسامة احمد اهلال",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.عبدالرزاق محمد ابوشاح",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "أ. خالد احمد الفرد",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
  {
    image: "",
    sex: "ذكر",
    name: "د.امحمد الفاندي",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },{
    image: "",
    sex: "ذكر",
    name: "أ. عبد الرحيم محمد احويش",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },{
    image: "",
    sex: "ذكر",
    name: "د.بلقاسم محمد السعيطي",
    type: "scientific",
    capacity: "عضو اللجنة العلمية",
  },
 ];

/////////////////////////////////////////////////////////الانجليزية/////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////اللجنة التقنية EN////////////////////////////////////////////
 export const CommiteeMembersEN = [

  {
    image: "",
    sex: "ذكر",
    name: "MR.Abdul Hamid Al-Murabit",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Youssef Al-Wafi",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Nadia Al-Sebani",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Ahmed Al-Arabi",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.latafiya hudud",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Ali Shalady",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Abdul Salam Abdo",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Ali Kanous",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Osama Bashina",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Karim Shoaib",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Abdul-Bari Al-Marimi",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Abdel Fattah Al Mazouhi",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
{
      image: "",
      sex: "انثى",
      name: "Mrs.Afaf Sheoub",
      type: "preparatory",
      capacity: "Member of the Preparatory Committee",
    },


  {
    image: "",
    sex: "ذكر",
    name: "Mr.Reda Fahil Al-Boom",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Mohammad Othman",
    type: "preparatory",
     capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Abu Bakr Al Mujajj",
    type: "preparatory",
     capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Alloun Salman",
    type: "preparatory",
     capacity: "Member of the Preparatory Committee",
  },
//   ///////////////////////////////////////////////*ENاللجنة العلمية*//////////////////////////////////////////////////////
  
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Nuri Flo",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Nuria Al-Hadi Abu Shaala",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Ali Ahmed Makhrof",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Khalid Mohamed Oun",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Mahmoud Al-Sadiq bin Isa",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Khaled Coco",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },

  {
    image: "",
    sex: "ذكر",
    name: "Mr.Hisham Awaj",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Abu Al-Qasim Al-Akhdar",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Adel Kashlaf",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Ibrahim Merihael",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Osama Ahmed Hilal",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Abdul Razzaq Mohammad Abushah",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Khaled Ahmed Al Fard",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Ali Jaloul",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Ali Abu Saluah",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Muhammad al-Ghadban",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Muhammad Qabasah",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Lutfi Ali Maddy",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Nadia Hussein Al-Sebani",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.M'hamed Al-Fandi",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },  {
    image: "",
    sex: "ذكر",
    name: "Mr.Abdul Rahim Mohammed Ahwish",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },  {
    image: "",
    sex: "ذكر",
    name: "Dr.Belkacem Mohamed Al Saeity",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
];
export const OtherComitteEN = [
//   //////////////////////////////////////////////*ENلجنة التواصل*//////////////////////////////////////////////////////

//   ///////////////////////////////////////////////*اللجنة التحضيرية*//////////////////////////////////////////////////////

  {
    image: "",
    sex: "ذكر",
    name: "Mr.Abdul Hamid Al-Murabit",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Nadia Al-Sebani",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Ahmed Al-Arabi",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Mrs.latafiya hudud",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Ali Shalady",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Abdul Salam Abdo",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Ali Kanous",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Osama Bashina",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Karim Shoaib",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Abdul-Bari Al-Marimi",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Abdel Fattah Al Mazouhi",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
{
      image: "",
      sex: "انثى",
      name: "Mrs.Afaf Sheoub",
      type: "preparatory",
      capacity: "Member of the Preparatory Committee",
    },


  {
    image: "",
    sex: "ذكر",
    name: "Mr.Reda Fahil Al-Boom",
    type: "preparatory",
    capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Mohammad Othman",
    type: "preparatory",
     capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Abu Bakr Al Mujajj",
    type: "preparatory",
     capacity: "Member of the Preparatory Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Alloun Salman",
    type: "preparatory",
     capacity: "Member of the Preparatory Committee",
  },
//   ///////////////////////////////////////////////*ENاللجنة العلمية*//////////////////////////////////////////////////////
  
  {
    image: "",
    sex: "ذكر",
    name: "D.Nuri Flo",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Nuria Al-Hadi Abu Shaala",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Ali Ahmed Makhrof",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Khalid Mohamed Oun",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Mahmoud Al-Sadiq bin Isa",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Khaled Coco",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },

  {
    image: "",
    sex: "ذكر",
    name: "Mr.Hisham Awaj",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Abu Al-Qasim Al-Akhdar",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Adel Kashlaf",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Ibrahim Merihael",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Osama Ahmed Hilal",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Abdul Razzaq Mohammad Abushah",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Khaled Ahmed Al Fard",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Ali Jaloul",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Ali Abu Saluah",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.Muhammad al-Ghadban",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Muhammad Qabasah",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Mr.Lutfi Ali Maddy",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "انثى",
    name: "Dr.Nadia Hussein Al-Sebani",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
  {
    image: "",
    sex: "ذكر",
    name: "Dr.M'hamed Al-Fandi",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },  {
    image: "",
    sex: "ذكر",
    name: "Mr.Abdul Rahim Mohammed Ahwish",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },  {
    image: "",
    sex: "ذكر",
    name: "Dr.Belkacem Mohamed Al Saeity",
    type: "scientific",
    capacity: "Member of the Scientific Committee",
  },
 ];

//////////////////////////////////////////////////////الفرنسية/////////////////////////////////////////////////
export const BossesEn = [
  {
    image: "./img/team/meftah.jpg",
    type: "none",
    name: "Dr.Miftah Al-Murabit ",
    capacity: "Honorary President of the Conference",
    
  },
  {
    image: "./img/team/omar.jpg",
    type: "none",
    name: "Dr.Omar Sultan",
    capacity: "Conference Chairman",
    // capacity1: "Général adjoint du Conseil national de planification libyen ",
  },
  {
    image: "./img/team/khdrawy.jpg",
    type: "none",
    name: "Mr.Abdul Azim Muhammad Al-Khidrawi",
    capacity: "Vice President of the conference",
    // capacity1: "عميد الكلية ",
  },
  {
    image: "./img/team/yousf.jpg",
    name: "Dr.Youssef Al-Mansouri ",
    type: "scientific",
    capacity: "Head of the Scientific Committee",
    committe: "Scientific"
  },
  {
    image: "./img/team/emad.jpg",
    name: "Mr.Emad Al-Ashkham",
    type: "preparatory",
    capacity: "Chairman of the Preparatory Committee",
    committe: "Preparatory"
  },


 ];
//////////////////////////////////////////////////////الفرنسية/////////////////////////////////////////////////
 export const BossesFR = [
  {
    image: "./img/team/emad.jpg",
    type: "none",
    name: "Dr.Omar Sultan",
    capacity: "Conference Chairman",
    // capacity1: "Général adjoint du Conseil national de planification libyen ",
  },
  {
    image: "./img/team/team2.jpg",
    name: "Prof. Aadalh Al-Ajal ",
    type: "scientific",
    capacity: "Chef du comité scientifique",
  },
  {
    image: "./img/team/team3.jpg",
    name: "Dr.Yassin Abusriwel",
    type: "preparatory",
    capacity: "Président du Comité préparatoire",
  },
  {
    image: "./img/team/meftah.jpg",
    type: "none",
    name: "Dr.Miftah Al-Murabit ",
    capacity: "Honorary President of the Conference",
  },
 ];

// /////////////////////////////////////////////////////////اللجنة التقنية FR/////////////////////////////////////
export const CommiteeMembersFR = [
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Mr.Hamid Hussein bin Kora",
//     capacity: "Membre du comité technique",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Mr.Ahmed Ibrahim Suleiman",
//     capacity: "Membre du comité technique",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Dr.Hassan Mouloud Al-Shaibani",
//     capacity: "Membre du comité technique",
//   },
//   {
//     image: "",
//     sex: "انثى",
//     name: "Mrs.Fatima Al-Shaibani Masoud",
//     capacity: "Membre du comité technique",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Mr.Hamza Moussa Al-Doukali ",
//     capacity: "Membre du comité technique",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Mr.Elias Abdullah Belhaj",
//     capacity: "Membre du comité technique",
//   },
//   ///////////////////////////////////////////////*FRاللجنة الاعلامية*//////////////////////////////////////////////////////
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Ahmed Saeed Al-Senussi",
//     capacity: "Chef du comité des médias",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Miqdad Salem Nusrat",
//     capacity: "Membre du comité des médias",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Mohammed Salem Abu Al-Qasim",
//     capacity: "Membre du comité des médias",
//   },
//   {
//     image: "",
//     sex: "انثى",
//     name: "Ewatif Al-bashir Al-bshty",
//     capacity: "Membre du comité des médias",
//   },
//   {
//     image: "",
//     sex: "انثى",
//     name: "Maysa Shaimaa Kribaza",
//     capacity: "Membre du comité des médias",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Abdul Majeed Mohammed Abu Shaala",
//     capacity: "Membre du comité des médias",
//   },
 ];
 export const OtherComitteFR = [
//   //////////////////////////////////////////////*FRلجنة التواصل*//////////////////////////////////////////////////////
//   {
//     image: "",
//     sex: "انثى",
//     name: "Mrs.Asma Al-Bashir Al-Shaibi",
//     capacity: "Chef du comité de communication",
//   },
//   {
//     image: "",
//     sex: "انثى",
//     name: "Mrs.Ahlam Nasser bin Tabon",
//     capacity: "Membre du comité de communication",
//   },
//   {
//     image: "",
//     sex: "انثى",
//     name: "Mrs.Wedad Ali Abu Shaqour",
//     capacity: "Membre du comité de communication",
//   },
//   {
//     image: "",
//     sex: "انثى",
//     name: "Mrs.Nour Abdullah Sharlala",
//     capacity: "Membre du comité de communication",
//   },
//   {
//     image: "",
//     sex: "انثى",
//     name: "Mrs.Amani Ibrahim Al-Arbash",
//     capacity: "Membre du comité de communication",
//   },
//   {
//     image: "",
//     sex: "انثى",
//     name: "Mrs.Asma Hussein Al-Aqili",
//     capacity: "Membre du comité de communication",
//   },
//   {
//     image: "",
//     sex: "انثى",
//     name: "Mrs.Marne Khalid Taib",
//     capacity: "Membre du comité de communication",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Mr.Mohammed Salem Ballout",
//     capacity: "Membre du comité de communication",
//   },
//   ///////////////////////////////////////////////*FRاللجنة التحضيرية*//////////////////////////////////////////////////////

//   {
//     image: "",
//     sex: "ذكر",
//     name: "Dr. Abdul Sami Amer Muammar",
//     type: "preparatory",
//     capacity: "Membre du comité préparatoire",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Dr.Hamza Al-Hadi Kashlaf",
//     type: "preparatory",
//     capacity: "Membre du comité préparatoire",
//   },
//   {
//     image: "",
//     sex: "انثى",
//     name: "Dr.Al-Zahra Sheikh Abdullah",
//     type: "preparatory",
//     capacity: "Membre du comité préparatoire",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Dr.Hassan Ali Mutat",
//     type: "preparatory",
//     capacity: "Membre du comité préparatoire",
//   },
//   {
//     image: "",
//     sex: "انثى",
//     name: "Mrs.Khawla bint Abdullah Al-Triki",
//     type: "preparatory",
//     capacity: "Membre du comité préparatoire",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Dr. Muhammad Al-Zatarini",
//     type: "preparatory",
//     capacity: "Membre du comité préparatoire",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Mesbah Salem Al Kanouni",
//     type: "preparatory",
//     capacity: "Membre du comité préparatoire",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Tariq Abdul Razzaq Al-Tashani",
//     type: "preparatory",
//     capacity: "Membre du comité préparatoire",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Mohammed Khalifa Al Marzouki",
//     type: "preparatory",
//     capacity: "Membre du comité préparatoire",
//   },
//   {
//     image: "",
//     sex: "انثى",
//     name: "Marwa Mohammed Al-Zarrouk",
//     type: "preparatory",
//     capacity: "Membre du comité préparatoire",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Dr. Abdullah Abdul Rahim",
//     type: "preparatory",
//     capacity: "Membre du comité préparatoire",
//   },
//   ///////////////////////////////////////////////*FRاللجنة العلمية*//////////////////////////////////////////////////////

//   {
//     image: "",
//     sex: "ذكر",
//     name: "Dr.Fella Muhammad",
//     type: "scientific",
//     capacity: "Membre du comité scientifique",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Dr.Barkaa Muhammad Al-Zein",
//     type: "scientific",
//     capacity: "Membre du comité scientifique",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Dr.Basem Kazem Khalaf",
//     type: "scientific",
//     capacity: "Membre du comité scientifique",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Dr.Mounir Farag Al Kabeer",
//     type: "scientific",
//     capacity: "Membre du comité scientifique",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Dr.Al-Jelly Mahjoub Ahmed",
//     type: "scientific",
//     capacity: "Membre du comité scientifique",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Dr.Khalasi Abdul Ilah",
//     type: "scientific",
//     capacity: "Membre du comité scientifique",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Dr.Mahmoud Ahmed Fahail album",
//     type: "scientific",
//     capacity: "Membre du comité scientifique",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Dr.Jalil Zine El Abidine",
//     type: "scientific",
//     capacity: "Membre du comité scientifique",
//   },
//   {
//     image: "",
//     sex: "انثى",
//     name: "Dr.Fida Ibrahim al-Masry",
//     type: "scientific",
//     capacity: "Membre du comité scientifique",
//   },
//   {
//     image: "",
//     sex: "انثى",
//     name: "Dr.Buthaina Al-Ghalbzouri",
//     type: "scientific",
//     capacity: "Membre du comité scientifique",
//   },
//   {
//     image: "",
//     sex: "انثى",
//     name: "Dr.Ayyad Layla",
//     type: "scientific",
//     capacity: "Membre du comité scientifique",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Dr.Miloud El Rahali",
//     type: "scientific",
//     capacity: "Membre du comité scientifique",
//   },
//   {
//     image: "",
//     sex: "انثى",
//     name: "Dr.Marsali Hawria",
//     type: "scientific",
//     capacity: "Membre du comité scientifique",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Dr.Ragab Abdel Salam Al-Amouri",
//     type: "scientific",
//     capacity: "Membre du comité scientifique",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Dr.Wadi Izz al-Din",
//     type: "scientific",
//     capacity: "Membre du comité scientifique",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Dr.Trakha Jamal",
//     type: "scientific",
//     capacity: "Membre du comité scientifique",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Dr.Mohammed Al-Shaibani Abu Shaafa",
//     type: "scientific",
//     capacity: "Membre du comité scientifique",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Yasser Al-Kirmani ",
//     type: "scientific",
//     capacity: "Membre du comité scientifique",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Dr.Abdel Salam Mohamed Belkacem",
//     type: "scientific",
//     capacity: "Membre du comité scientifique",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Dr.Abdullah Ramadan Benini",
//     type: "scientific",
//     capacity: "Membre du comité scientifique",
//   },
//   {
//     image: "",
//     sex: "ذكر",
//     name: "Dr.Slaf Alsamawii",
//     type: "scientific",
//     capacity: "Membre du comité scientifique",
//   },



];

export const vedname = {
  en : "Une vidéo explicative en français expliquant les objectifs et les thèmes de la conférence" ,
  ar : "فيديو  يوضح أهداف ومحاور المؤتمر. " , 
  fr : "Une vidéo explicative en français expliquant les objectifs et les thèmes de la conférence"}

  export const btnTitle = {
    en : "How to Register" ,
     ar : "كيفية التسجيل" ,
     fr: "Comment s'inscrire"
    }
    export const btnTitleG = {
      en : "Thèmes de la conférence" ,
       ar : "شرح محاور المؤتمر" ,
       fr: "Thèmes de la conférence"
      }