import React, { Fragment, useEffect } from "react";
import Timer from "../timer/timer";
import { head1, head2, head3, head4, vedname, head5,btnTitle ,btnTitleG} from "../sections";
import Regester from "../modals/Regester";
import { connect } from "react-redux";
import VideoModals from "../modals/VideoModals";
import { getResearchs } from "../../actions/research";

const Header = ({ lang, researchs, getResearchs }) => {
  useEffect(() => {
    getResearchs();
  }, []);
 

  

  let URL = {en : "https://youtu.be/jaswMCJRkYs" , ar : "https://youtu.be/jaswMCJRkYs", }

  let URLG = {en : "https://youtu.be/H8_DBhL4jXg" , ar : "https://youtu.be/H8_DBhL4jXg",}
  let researchsNumber = researchs.length;
  return (
    <section className="showcase text-center" id="mains">
      <div className="video-container">
        <video src="/img/1.mp4" autoPlay muted loop />
      </div>
      <div className="content ">
        <h1>{head1[lang]}</h1>
        <h2 dangerouslySetInnerHTML={{ __html: head2[lang] }}></h2>
        <h3>
          <h4 className="my-3">{head5[lang]} </h4>
          <Timer lang={lang} />
        </h3>
        <br />
        <h2>
          {" "}
          {head3[lang]}
          {"   "}
          <span> {researchsNumber}</span>
          {"   "}
          {head4[lang]} {"   "} 0{"   "}
        </h2>
        <br />
        
        {
          Date.now() > new Date("2020-09-01") && (
            <Fragment>
               <a  className="btn btn-our mx-2 " href="./img/pro.pdf" target="_blank">لتحميل مطوية مسابقة التميز </a>
               {/* <VideoModals 
               lang={lang} 
               URI={URLG} 
               videoname= {vedname}
               btnTitle = {btnTitleG[lang]}
              /> */}
              <Regester lang={lang} />
              <a  className="btn btn-our mx-2 " href="./img/regform.docx" target="_blank">لتحميل نموذج المشاركة في المسابقة</a>
              {/* <VideoModals 
              lang={lang} 
              URI={URL}  
              videoname= {btnTitle}
              btnTitle = {btnTitle[lang]}
              /> */}
              
            </Fragment>
          )
          // : (
          //   <a
          //     href="#list"
          //     className="modal-trigger   btn-large red darken-2 waves-effect waves-light btn "
          //   >
          //     {" "}
          //     <i class="material-icons left">format_list_numbered</i>
          //     {lang === "ar"
          //       ? " قائمة الملخصات المقبولة"
          //       : " accepted Abstructs list "}
          //   </a>
          // )
        }
      </div>
    </section>
  );
};
const mapStateToProps = (state) => ({
  researchs: state.research.researchs,
});
export default connect(mapStateToProps, { getResearchs })(Header);
