import React from "react";
import { s, navlink5 } from "../sections";
const Suports = ({ lang }) => {
  return (
    <section class="sections services py-5 " id="suports">
      <div class="container">
        <div class="section-header text-center text-white my-4">
          <h2 class="section-title">{navlink5[lang]}</h2>
          <div class="line">
            <span></span>
          </div>
        </div>

        <div class="row my-3">
          <div class="col-md-12 col-xs-12" data-aos="fade-dow">
            <div class="serv ">
            {/* eslint-disable-next-line */}
              {/* <a href="http://arc.com.ly/ar/" target="_blank"></a> */}
              <img src="./img/sp.jpg" alt="s" width="800" />
              
            </div>
          </div>
          {/* <div class="col-md-4 col-xs-12" data-aos="fade-dow">
            <div class="serv ">
             
              <a href="https://noc.ly/index.php/ar/" target="_blank">
              <img src="./img/petro.png" alt="s" width="250" />
              </a>
            </div>
          </div>
          <div class="col-md-4 col-xs-12" data-aos="fade-dow">
            <div class="serv ">
              {/* eslint-disable-next-line 
              <a href="https://www.lcrsss.ly/" target="_blank">
              <img src="./img/comm1.png" alt="s" width="250" />
              </a>
            </div>
          </div>
          <div class="col-md-4 col-xs-12" data-aos="fade-dow">
            <div class="serv ">
              {/* eslint-disable-next-line 
              <a  target="_blank">
              <img src="./img/comm.png" alt="s" width="250" />
              </a>
            </div>
          </div>
           <div class="col-md-4 col-xs-12" data-aos="fade-dow">
            <div class="serv ">
              {/* eslint-disable-next-line *
              <a href="http://alrahila.ly/" target="_blank">
              <img src="./img/ALRAHILA.png" alt="s" width="250" />
              </a>
            </div>
          </div>
           <div class="col-md-4 col-xs-12" data-aos="fade-dow">
            <div class="serv ">
              {/* eslint-disable-next-line *
              <a  target="_blank">
              <img src="./img/env.png" alt="s" width="250" />
              </a>
            </div>
          </div> 
          <div class="col-md-4 col-xs-12" data-aos="fade-dow">
            <div class="serv ">
              {/* eslint-disable-next-line *
              <a href="http://gwa.ly/" target="_blank">
              <img src="./img/water.png" alt="s" width="250" />
              </a>
            </div>
          </div> 
          <div class="col-md-4 col-xs-12" data-aos="fade-dow">
            <div class="serv ">
              {/* eslint-disable-next-line *
              <a href="https://nafusah.com/ar/" target="_blank">
              <img src="./img/nafusa.png" alt="s" width="250" />
              </a>
            </div>
          </div> */}


        </div>
      </div>
    </section>
  );
};

export default Suports;
