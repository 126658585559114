import React from "react";
import { footer } from "../sections";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-solid-svg-icons";

const Footer = ({ lang }) => {
  return (
    <>
      <div class="sections copyright py-4 text-center">
        <div class="container">
          <p class="copy-desc">
            &copy; {footer[lang]}
            {"  "}
            {new Date().getFullYear()}{" "}
         
          </p>
          <a target="_blank" style={{textDecoration : "none" ,cursor : "pointer" ,color : "rgb(13, 110, 148)"}} href="#"> FaceBook </a>
        </div>
       
      </div>
    </>
  );
};

export default Footer;
